import gsap from "gsap";
import { isMobile } from "../../app";


class Menu{
    constructor(data){
        this.navbar = data.next.container.querySelector('.navbar')
        this.navbarOthers = data.next.container.querySelectorAll("[class^=navbar-container__]")
        this.navbarContainer = data.next.container.querySelector('.navbar-container')
        this.navbarItems = data.next.container.querySelectorAll('.navbar-items .navbar-item')
        this.burgerMenu = data.next.container.querySelector('.burger-menu')
        this.isMenuActive = false,
        this.isBurgerHovered = false,
        this.navbarTL = gsap.timeline({paused: true})
        this.burgerTL = gsap.timeline({paused: true})
        
        this.setNavbarTL()
        this.setBurgerTL()
    }
    
    setNavbarTL(){
        this.navbarTL
        .to(this.navbarContainer, {y: 0, ease: 'power4.inOut', duration: 1},0)
        .from(this.navbarItems, {y: '-100%', opacity: 0, stagger: .1, ease: 'circle.inOut', duration: .5}, '-=.4')
        .from(this.navbarOthers, { opacity: 0, stagger: .1, ease: 'power0.out', duration: .5}, '-=.5');
    }
    setBurgerTL(){
        let delay = .25
        this.burgerTL
            .to(this.burgerMenu.children[0], { delay: delay, duration:.3, top: '50%', bottom: 'auto', ease: 'ease'},0)
            .to(this.burgerMenu.children[1], { delay: delay, duration:.3,opacity:0, ease: 'ease'},0)
            .to(this.burgerMenu.children[2], { delay: delay, duration:.3, top: '50%', bottom: 'auto', ease: 'ease'},0)
            .to(this.burgerMenu.children[0], { duration:.5, transformOrigin:'center center', rotate: 90, ease: 'power2.out'}, delay + .25)
            .to(this.burgerMenu, { rotate: 45, duration:.5, ease: 'power2.out'}, delay + .35)
    }

    menuActivation(){
        this.isMenuActive = !this.isMenuActive

        if(this.isMenuActive){
            this.navbar.classList.add('active')
            this.navbarTL.play()
            this.burgerTL.play()
            document.body.style.overflow = 'hidden'
        }else{
            this.navbar.classList.remove('active')
            this.navbarTL.reverse()
            this.burgerTL.reverse()
            document.body.style.overflow = ''
        } 
    }

    hoverAnim(){
        this.isBurgerHovered = true
        const tl = gsap.timeline({paused:true, yoyo: true})
        tl
        .fromTo(this.burgerMenu.children, {scaleX: 1},{scaleX: .1, transformOrigin: 'left top', stagger: {
            each: .1,
            repeat: 1,
            yoyo: true,
            yoyoEase: 'ease'
        }, duration: .4,})
        
        this.isBurgerHovered && this.isMenuActive === false ? tl.play() : tl.reverse();
    }
}


export function menuToggle(data){
    const menuAction = new Menu(data)

    menuAction.burgerMenu.addEventListener('click', function(){
        menuAction.menuActivation()
    })

    menuAction.burgerMenu.addEventListener('mouseenter', function(){
        if(menuAction.isMenuActive === false && !isMobile()){
            menuAction.hoverAnim()
        }
    })
    
    menuAction.navbarItems.forEach(item => {
        item.addEventListener('click', ()=>{
            menuAction.menuActivation()
        })
    })
}