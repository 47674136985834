import gsap from "gsap";

export function marqueText(container){
    if(container != undefined){
        const isMarqueeContainer = !!container.querySelector('.marquee-texts')
        if(isMarqueeContainer){
            const marqueeContainer = container.querySelector('.marquee-texts')
            marqueeContainer.innerHTML = ''
            for (let i = 0; i < 30; i++) {
                marqueeContainer.innerHTML += `<li>${marqueeContainer.dataset.text}</li>`
            }
    
            setTimeout(()=>{
                const marqueeTexts = container.querySelectorAll('.marquee-texts > li')
                const directionLengthY = marqueeTexts[0].clientWidth;
                const directionLengthX = marqueeTexts[0].clientWidth;
                if(window.innerWidth > 1024){
                    gsap.to(marqueeTexts, {
                        x: `+=${directionLengthY}`, 
                        ease: "none",
                        duration: 5, 
                        modifiers:{
                            x: gsap.utils.unitize(x => {
                               return parseFloat(x) % directionLengthY
                            }) 
                        },
                        repeat: -1
                    })
                }else{
                    gsap.to(marqueeTexts, {
                        x: `+=${directionLengthX}`, 
                        ease: "none",
                        duration: 5, 
                        modifiers:{
                            x: gsap.utils.unitize(x => {
                               return parseFloat(x) % directionLengthX
                            }) 
                        },
                        repeat: -1
                    })
                }
            }, 1000)
        }
    }
}