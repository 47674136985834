//Generate detail page
function generateTeam(datas){
    const members = datas[0].member
    const container = document.querySelector('.teams-body')
    let html = ``;
    for (let i = 0; i < members.length; i++) {
        
    html += `
        <div class="team-member">
            <div class="team-member__image ${members[i].color == 1 ? 'bg-primary' : members[i].color == 2 ? 'bg-indigo' : members[i].color == 0 ? 'bg-dark' : '' }">
                <img src="${members[i].image}" alt="">
            </div>
            <div class="team-member__bio">
                <h3 class="team-member__bio__name">${members[i].name.first} ${members[i].name.last}</h3>
                <h3 class="team-member__bio__position">${members[i].occupation}</h3>
                <p class="wp-normal">${members[i].descr}</p>
            </div>
        </div>
        `
    }
    container.innerHTML = html

    const itemWidth = document.querySelector('.team-member').clientWidth

    document.querySelector('.teams-body').style.width = itemWidth * members.length
}

export default generateTeam