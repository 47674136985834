//Handle all fetch requests
export async function getJSON(url){
    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        throw error
    }
}

export async function getWorks(url){
    const dataJSON = await getJSON(url);
    const works = [dataJSON.works].map(async (work)=> {
        return {work}
    })

    return Promise.all(works)
}

export async function getTeam(url){
    const dataJSON = await getJSON(url);
    const team = [dataJSON.staff].map(async (member)=> {
        return {member}
    })

    return Promise.all(team)
}

 //Generate work lists
 export function generateWorks(datas) {
    const workContainer = document.getElementById('work-container')
    let html = ''
    datas[0].work.map( (work, index) => {
        const detail = work.main.mobileViewer ? 'detail2' : 'detail';
        
        html = `
        <li class="plural-works__grid-item ${work.main.gridAlign}">
            <article class="plural-works__card">
                <a href="/works/${detail}.html?portfolio=${index}">
                    <div class="plural-works__card-img">
                        <img src="/static/images/works/w${index}/thumb/thumb.jpg" alt="">
                    </div>
                    <div class="plural-works__card-details">
                        <h2> <span class="t l1">${work.main.title.l1}</span><br> <span
                                class="t be">${work.main.title.l2}</span> <br /><span
                                class="t l1">${work.main.title.l3}</span></h2>
                    </div>
                </a>
            </article>
        </li>
        `
        workContainer.innerHTML += html
    } )
}