//Generate detail page
function generateWorkDetail(barba, datas){
    const id = barba.next.url.query.portfolio   
    const splash = document.getElementById('splash-img')
    const mobileScroll = document.getElementById('mb-screen')
    const title = document.getElementById('main-title')
    const client = document.getElementById('client')
    const type = document.getElementById('type')
    const desc = document.getElementById('desc')
    const subtitle = document.getElementById('subtitle')
    const gallery = document.querySelector('.plural-works__grid')
    const data = datas[0].work[id]
    
    //insert content
    splash.innerHTML = `<img id="splash" src="/static/images/works/w${id}/main/splash.jpg" alt="">`
    title.innerHTML = `<span class="t l1 mutate-text">${data.main.title.l1}</span><br> <span class="t be mutate-text">${data.main.title.l2}</span> <br/><span class="t l1 mutate-text">${data.main.title.l3}</span>`
    client.innerHTML = data.main.client
    type.innerHTML = data.main.type
    desc.innerHTML = data.main.descr

    if(data.main.mobileViewer){
        const visitLink = document.querySelector('#visitLink')
        visitLink.setAttribute('href', data.main.visitLink)

        mobileScroll.innerHTML = `
            <div class="scroll-mobile-view__grid__scroll-gallery">
                <img src="/static/images/works/w${id}/strips/img0.jpg" data-scroll data-scroll-direction="vertical" class="scroll-image" data-scroll-speed="3" alt="">
                <div class="mobile-frame" data-scroll data-scroll-direction="vertical" data-scroll-speed="-1" ></div>
            </div>
            <div class="scroll-mobile-view__grid__scroll-gallery">
                <img src="/static/images/works/w${id}/strips/img1.jpg" data-scroll data-scroll-direction="vertical" class="scroll-image" data-scroll-speed="-3" alt="">
                <div class="mobile-frame" data-scroll data-scroll-direction="vertical" data-scroll-speed="1"></div>
            </div>
        `
    }

    const splitTitleSplit = data.main.subtitle.split(' ')
    for (let i = 0; i < splitTitleSplit.length; i++) {
        if(i % 2 != 0){
            subtitle.innerHTML += `<span class="t be">${splitTitleSplit[i]}</span> `
        }else{
            subtitle.innerHTML += `<span class="t l1">${splitTitleSplit[i]}</span> `
        }
    }
    for (let i = 0; i < data.main.gallery.length; i++) {
        const format = data.main.gallery[i][0].split('.')
        if(format[1] === 'jpg' || format[1] === 'png' || format[1] === 'gif' || format[1] === 'jpeg'){
            gallery.innerHTML += `
            <li class="plural-works__grid-item ${data.main.gallery[i][1]}">
                <figure data-scroll data-scroll-offset="25%">
                    <img src="/static/images/works/w${id}/main/${data.main.gallery[i][0]}" alt="">
                </figure>
            </li>
            `
        }else if(format[1] === 'mp4'){
            const li = document.createElement('li')
            const videoEl = document.createElement('video')
            const source = document.createElement('source')
            li.className = `plural-works__grid-item ${data.main.gallery[i][1]}`
            videoEl.setAttribute('data-scroll', '')
            videoEl.setAttribute('data-scroll-offset', '25%')
            videoEl.setAttribute('poster', `/static/images/works/w${id}/main/${data.main.gallery[i]}-poster.jpg`)
            source.src = `/static/images/works/w${id}/main/${data.main.gallery[i][0]}`
            source.type = 'video/mp4'
            videoEl.setAttribute('id', 'mainVideo')
            videoEl.setAttribute('muted', '')
            videoEl.muted = true
            videoEl.controls = false
            videoEl.loop = true
            videoEl.autoplay = true
            videoEl.playsInline = true
            videoEl.appendChild(source)
            li.appendChild(videoEl)
            gallery.appendChild(li)
            setTimeout(() => {
                videoEl.play()
                console.log(videoEl.paused)
                while(videoEl.paused === true){
                    console.log('loop')
                    videoEl.play()
                }
            }, 300);
        }
    }
}

export default generateWorkDetail