import gsap from "gsap"

export default class shapeMorph{
    constructor(shape, shape2){
        this.shape = shape,
        this.shape2 = shape2,
        this.isComplete = false
    }

    animate(){
        const tl = gsap.timeline({
            onStart: ()=> {
                this.isComplete = !this.isComplete
            }
        })
        if(this.isComplete){
            tl.to(this.shape, {duration:0.5,ease:'circ.inOut()',morphSVG:{shape: this.shape ,type:'rotational',origin:'0% 0%'}})
        }else{
            tl.to(this.shape, {duration:0.5,ease:'circ.inOut()',morphSVG:{shape: this.shape2 ,type:'rotational',origin:'0% 0%'}})
        }
        return tl
    }
}