import { gsap } from "gsap";

export default function preloaderState(){
    return gsap.to('#pagePreloader', {
        y: '-100%', 
        duration: .7, 
        ease: 'power3.out', 
        delay: .5,
        onComplete: function(){
            this.targets()[0].classList.add('done')
        }
    })
}